import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import OtpInput from "react-otp-input";
import { validateOtpForMfa } from "../../services/userService";
import { errorToast, successToast } from "../Toasts/Toasts";
import './MFASetup.css';

const MfaModal = ({ show, handleClose, qrCodeUrl, SetRenderProfileData, renderProfileData, mfaValue }) => {
  const [otpCode, setOtpCode] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  // Handler for OTP input
  const handleOtpChange = (otp) => {
    setOtpCode(otp);
    setError("");
  };

  // Handler for form submission
  const handleSubmit = async () => {
    if (otpCode.length === 6) {
      setLoading(true); // Start loading when submitting

      // Initialize obj with proper assignment
      const obj = mfaValue === 1 ? { token: otpCode, isDisabled: true } : { token: otpCode };

      try {
        const response = await validateOtpForMfa(obj);
        if (response?.success) {
          successToast(response?.message);
          handleClose(); // Close the modal on success
          SetRenderProfileData(!renderProfileData);
        } else {
          errorToast(response?.message);
        }
      } catch (error) {
        errorToast(error?.message || "Something went wrong");
      } finally {
        setLoading(false); // Stop loading when request completes
      }
    } else {
      setError("Please enter a valid 6-digit OTP.");
    }
  };

  return (
<Modal show={show} size="md" centered>
  <div className="modal-simple-mfa">
    <div className="p-3 p-md-3">
      <Modal.Body>
        <button type="button" className="btn-close" onClick={handleClose} />
        <div className="text-left mb-0">
          {mfaValue === 1 ? <h4 className="mb-3">Disable MFA</h4> : <h4 className="mb-3"></h4>}
        </div>

        {mfaValue === 0 && (
          <div className="setup-device">
            <h4>Set up device</h4>
            <div className="authenticator-app">
              <h3>Authenticator app</h3>
              <p>A virtual MFA device is an application running on your device that you can configure by scanning a QR code.</p>
              
              <div className="step">
                <span className="step-number">1</span>
                <div className="step-content">
                  <p>Install a compatible application such as Google Authenticator, Microsoft Authenticator, or Authy app on your mobile device or computer.</p>
                </div>
              </div>

              <div className="step">
                <span className="step-number">2</span>
                <div className="step-content">
                  <div className="qr-box">
                    {qrCodeUrl ? <img src={qrCodeUrl} alt="MFA QR Code" style={{ maxWidth: "100%", height: "auto" }} /> : <p>Show QR code</p>}
                  </div>
                  <p>Open your authenticator app, scan <strong>Shown QR code</strong> on this page.</p>
                </div>
              </div>

              <div className="step">
                <span className="step-number">3</span>
                <div className="step-content">
                  <p>Type the six-digit code from your authenticator app into the MFA codes below:</p>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* OTP Input Section */}
        <div className="mt-4">
          <label>Enter 6-Digit OTP</label>
          <OtpInput
            value={otpCode}
            onChange={handleOtpChange}
            numInputs={6}
            renderSeparator={<span> </span>}
            renderInput={(props) => (
              <input {...props} className={`my-2 me-3 text-center form-control rounded otp-input-width ${error ? "is-invalid" : ""}`} />
            )}
            shouldAutoFocus={true}
          />
          {error && <div className="text-danger mt-2">{error}</div>}
        </div>

        <div className="text-center mt-4">
          <Button variant="primary" onClick={handleSubmit} className="rounded-pill" disabled={loading}>
            Submit
          </Button>
        </div>
      </Modal.Body>
    </div>
  </div>
</Modal>

  );
};

export default MfaModal;
