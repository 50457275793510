import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
const ViewCustomerHistoryModal = ({ showHistoryModal, setShowHistoryModal, recipientDetails, customerName}) => {
  const handleClose = () => setShowHistoryModal(false);
  return (
    <Modal show={showHistoryModal} size="md" centered>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <Modal.Body>
            <button type="button" className="btn-close" onClick={handleClose} />
            <div className="text-left mb-4">
              <h3 className="mb-2">View recipient details</h3>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Transaction ID</h6>
                <small className="text-muted">{recipientDetails?.transaction_id}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Country Code</h6>
                <small className="text-muted">{recipientDetails?.phone_code}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Mobile Number</h6>
                <small className="text-muted"> {recipientDetails?.mobile_number}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Requester's Name</h6>
                <small className="text-muted"> {recipientDetails?.requested_by === null ? customerName : recipientDetails?.requested_by}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Carrier</h6>
                <small className="text-muted"> {recipientDetails?.carrier}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">
                  Top-Up <small>(USD)</small>
                </h6>
                <small className="text-muted"> {recipientDetails?.topup_amount_converted_currency}</small>
              </div>

              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Date</h6>
                <small className="text-muted"> {moment(recipientDetails?.createdAt).format('DD MMM YY, hh:mm A')}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Status</h6>
                <small className="text-Successful"> {recipientDetails?.status}</small>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12 bg-lighter-blue rounded px-4">
                <div className="d-flex w-100 flex-wrap justify-content-between py-1 mt-3">
                  <div className="me-2">
                    <h6 className="mb-0">Amount</h6>
                  </div>
                  <div>
                    <div className="user-progress d-flex align-items-center gap-1">
                      <p className="mb-0 fw-medium h5">
                        <span className="text-primary me-2">
                          ({Number(recipientDetails?.topup_amount_source_currency || 0).toFixed(2)} <small>{recipientDetails?.original_currency_type}</small>)
                        </span>
                        <span className="h5">
                          {Number(recipientDetails?.topup_amount_converted_currency || 0).toFixed(2)} <small>{recipientDetails?.converted_currency_type}</small>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-1">
                  <div className="me-2">
                    <h6 className="mb-0">Service Fee</h6>
                  </div>
                  <div className="user-progress d-flex align-items-center gap-1">
                    <p className="mb-0 fw-medium h6">
                      {Number(recipientDetails?.service_fee || 0).toFixed(2)} <small>USD</small>
                    </p>
                  </div>
                </div>
                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-1">
                  <div className="me-2">
                    <h6 className="mb-0">Taxes</h6>
                  </div>
                  <div className="user-progress d-flex align-items-center gap-1">
                    <p className="mb-0 fw-medium h6">
                      {Number(recipientDetails?.vat || 0).toFixed(2)} <small>USD</small>
                    </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between border-top mt-2 pt-2">
                  <h6 className="fw-bold">Total</h6>
                  <div>
                    <p className="text-primary h6 fw-bold">
                    {Number(recipientDetails?.total_amount_converted_currency || 0).toFixed(2)} <small>{recipientDetails?.converted_currency_type}</small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
        </div>
      </div>
    </Modal>
  );
};

export default ViewCustomerHistoryModal;
