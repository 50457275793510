import React, { useCallback, useEffect } from "react";
import { fetchCurrency, fetchProductSelect } from "../../../services/transactionService";
import { errorToast } from "../../Toasts/Toasts";
import { useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

const ProductSelectRetailer = ({ showProductSelect, setProductAmount, setFinalCommission, setProductId }) => {
  const [productData, setProductData] = useState("");
  const [convertedCurrency, setConvertedCurrency] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [size, setSize] = useState(10);
  
  let page = 1;

  useEffect(() => {
    fetchCardCurrency();

    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    if (!showProductSelect) {
      // Reset selected product when ProductSelectRetailer is hidden
      setSelectedOption(null);
    }

    // eslint-disable-next-line
  }, [showProductSelect]);

  (()=> setSearchQuery)();

  useEffect(() => {
    handleProductData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    try {
      const response = await fetchProductSelect(page, size + 10, searchQuery);
      if (response?.success) {
        const newData = response?.data?.records;
        if (newData?.length > 0 && newData?.length > currentResultSetLength) {
          setProductData(newData);
          setSize((prevSize) => prevSize + 10); // Increment page number
          setCurrentResultSetLength(newData?.length);
        } else {
          setHasMoreData(false);
        }
      } else {
        errorToast("Error occurred while loading more data");
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while loading more data");
    } finally {
      setLoading(false);
    }
    
  }, [loading, hasMoreData, page, searchQuery, size, currentResultSetLength]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("productSelectionComponent");
      if (parseInt(scrollContainer.scrollHeight - scrollContainer.scrollTop) === parseInt(scrollContainer.clientHeight)) {
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("productSelectionComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  const handleProductData = async () => {
    try {
      const response = await fetchProductSelect(page, size, searchQuery);
      if (response?.success) {
        setProductData(response?.data?.records)
        setHasMoreData(response?.data?.records.length === size); // Set hasMore based on whether there's more data to load
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  const fetchCardCurrency = async () => {
    try {
      const response = await fetchCurrency();

      if (response?.success) {
        setConvertedCurrency(response?.data);
      } else {
        errorToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred while fetching card currency.");
    }
  };

  let timeoutId;
  const basicSearch = async (event) => {
    const keyword = event?.target?.value?.trim();
    if (keyword !== "") {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        try {
          const response = await fetchProductSelect(page, size);
          const filteredData = response?.data?.records.filter((record) => record?.product_price?.toLowerCase().includes(keyword.toLowerCase()));
          setProductData(filteredData);
          
        } catch (error) {
          setProductData([]);
          // Handle error here
          console.error("An error occurred during search:", error);
        }
      }, 1200);
    } else {
      clearTimeout(timeoutId); // Clear the timeout if input is empty
      setProductData([]); // Clear product data when input is empty
      handleProductData();
    }
  };
  


  const handleRadioSelect = (index) => {
    let AfnValue = productData[index];
    let value = ((Number(AfnValue?.product_price) + (Number(AfnValue?.product_price) * Number(AfnValue?.final_commission)) / 100) / parseFloat(convertedCurrency?.retailerConversion?.converted_currency)).toFixed(4);
    setProductAmount(value);
    setFinalCommission(AfnValue?.final_commission);
    setProductId(AfnValue?.product_id);
    setSelectedOption(index);
  };

  return (
    <>
      {showProductSelect && (
        <div className="col-md-4 top-up-plans" style={{ display: "block" }}>
          <div className="card">
            <div className="card-header border-bottom mb-0 d-flex justify-content-between">
              <div className="card-title mb-0">
                <h5 className="mb-0">Products</h5>
              </div>
            </div>
            <div className="card-body p-4">
              <div className="row">
                <div className="col-lg-10">
                  <input type="text" placeholder="Search for products" className="form-control mb-0" onChange={basicSearch} />
                </div>

                <div className="border-bottom border-bottom-dashed mt-3 mb-4" />
                <InfiniteScroll dataLength={productData.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : ""} scrollableTarget="productSelectionComponent">
                  <div className="px-0 py-0 ps-2" id="productSelectionComponent" style={{ height: "400px", overflowY: "auto" }}>
                    <div className="list-group mt-0 me-1">
                      <div className="list-group mt-0 me-1">
                        {productData &&
                          productData?.map((productDetails, index) => (
                            <div className={`form-check custom-option custom-option-basic w-100 ${selectedOption === index ? 'checked' : ''}`} key={index}>
                              <label className="d-flex justify-content-between form-check-label custom-option-content" htmlFor={`customRadioTemp${index}`}>
                                <div>
                                  <p className="mb-0 text-primary h5">
                                    {((Number(productDetails?.product_price) + (Number(productDetails?.product_price) * Number(productDetails?.final_commission)) / 100) / parseFloat(convertedCurrency?.retailerConversion?.converted_currency)).toFixed(2)}
                                    <small> {convertedCurrency?.retailerConversion?.source_currency_code}</small> <small className="text-muted ms-2">(Approx.)</small>
                                  </p>
                                  <h6 className="mb-0 h6">
                                    {(Number(productDetails?.product_price) + (Number(productDetails?.product_price) * Number(productDetails?.final_commission)) / 100).toFixed(2)}
                                    <small> {convertedCurrency?.retailerConversion?.target_currency_code}</small> <small className="text-muted ms-2">(Approx.)</small>
                                  </h6>
                                </div>
                                <div className="mt-2 pt-1">
                                  <input name="select-plan" className="form-check-input" type="radio" value={index} id={`customRadioTemp${index}`} onChange={() => handleRadioSelect(index)} />
                                </div>
                              </label>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </InfiniteScroll>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ProductSelectRetailer;
