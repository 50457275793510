import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { errorToast, successToast } from "../../Toasts/Toasts";
import Select from "react-select";
import { clearFilteredChips, postAdminCustomerFilterApi, setFilteredChips } from "../../../redux/features/adminCustomerSlice";
import { useDispatch } from "react-redux";

const CustomersFilterModal = ({ masterData, clearFilterValues, currentSize, setClearFilterValues }) => {

  const [showFilterBox, setShowFilterBox] = useState(false);
  const [carrier, setCarrier] = useState("");
  const [status, setStatus] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [name, setName] = useState("");
  const dispatch = useDispatch();

  let page = 1;
  useEffect(() => {
    const filterValue = () => {
      if (clearFilterValues === "name") setName("");
      if (clearFilterValues === "carrier_id") setCarrier("");
      if (clearFilterValues === "phone_number") setPhoneNumber("");
      if (clearFilterValues === "phone_code") setCountryCode("");
      if (clearFilterValues === "is_active") setStatus("");

      setClearFilterValues("");
    };

    filterValue();
    // eslint-disable-next-line
  }, [clearFilterValues]);

  const handleClose = () => {
    setShowFilterBox(!showFilterBox);
  };

  const statusOption = [
    { label: "Active", value: "1" },
    { label: "Inactive", value: "0" },
  ];

  const carrierOption = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const filterData = {
    carrier_id: carrier !== "" ? carrier?.value : "",
    is_active: status !== "" ? status?.value : "",
    phone_code: countryCode !== "" ? countryCode : "",
    phone_number: phoneNumber !== "" ? phoneNumber : "",
    name: name !== "" ? name : "",
  };

  const filterChipsData = {
    carrier_id: carrier !== "" ? carrier : "",
    is_active: status !== "" ? status : "",
    phone_code: countryCode !== "" ? countryCode : "",
    phone_number: phoneNumber !== "" ? phoneNumber : "",
    name: name !== "" ? name : "",
  };

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(filterData);
  const filteredChipsData = filterEmptyFields(filterChipsData);

  const submitFilterData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every((value) => {
      return value === "";
    });

    if (!isFilterEmpty) {
      try {
        const response = await dispatch(postAdminCustomerFilterApi({ filteredData: filteredData, page, size: currentSize }));

        dispatch(setFilteredChips(Object.entries(filteredChipsData)));

        if (response?.meta?.requestStatus === "fulfilled") {
          successToast("Filter applied successfully");
        } else {
          errorToast("Error occurred while filtering");
        }

        handleClose();
      } catch(error) {
        errorToast(error?.message)
      }

    } else {
      errorToast("No field selected for filter");
    }
  };

  const handleResetButton = () => {
    setStatus("");
    setCountryCode("");
    setCarrier("");
    setPhoneNumber("");
    setName("");
    try {
      dispatch(postAdminCustomerFilterApi({ filteredData: {}, page, size: currentSize }));
    } catch(error) {
      errorToast(error?.message);
    }

    dispatch(clearFilteredChips());
    handleClose();
  };

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light" onClick={() => setShowFilterBox(true)}>
        <i className="ti ti-filter me-1" /> Filter
      </button>
      {
        <Modal show={showFilterBox} centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <div className="modal-body ">
                <button type="button" className="btn-close" onClick={handleClose} aria-label="Close" />
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-5">
                    <label className="form-label">Country Code</label>
                    <input type="text" placeholder="" className="form-control mb-3" value={countryCode} onChange={(e) => setCountryCode(e.target.value)} />
                  </div>
                  <div className="col-lg-7">
                    <label className="form-label">Mobile Number</label>
                    <input type="text" placeholder="" className="form-control mb-3" value={phoneNumber} onChange={(e) => setPhoneNumber(e.target.value)} />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Name</label>
                    <input type="text" placeholder="" className="form-control mb-3" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>

                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Carrier</label>
                    <Select options={carrierOption()} isMulti={false} onChange={setCarrier} value={carrier} />
                  </div>
                  <div className="col-lg-6">
                    <label className="form-label">Status</label>
                    <Select options={statusOption} isMulti={false} onChange={setStatus} value={status} />
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" onClick={submitFilterData} className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter">
                      Submit
                    </button>
                    <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleResetButton}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default CustomersFilterModal;
