import React, { useCallback, useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { postProductFilterApi, setProductFilteredChips } from "../../../redux/features/productSlice";
import moment from "moment";
import EditPriceModal from "../../Shared/ManageProduct/EditPriceModal";
import ProductHistoryModal from "../../Shared/ManageProduct/ProductHistoryModal";
import ViewProductModal from "./ViewProductModal";
import InfiniteScroll from "react-infinite-scroll-component";
import { errorToast } from "../../Toasts/Toasts";
import localStorageStore from "../../../utils/localStorageStore";

const ProductsGrid = ({ setClearFilterValues, productDetails, userDetail, account_no ,currentSize, setCurrentSize }) => {
  const [showModalBox, setShowModalBox] = useState(false);
  const [showHistoryModalBox, setShowHistoryModalBox] = useState(false);
  const [showProductDetails, setShowProductDetails] = useState(false);
  const [productAllDetails, setProductAllDetails] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength ,setCurrentResultSetLength] = useState(0);
  const [productId, setProductId] = useState("");
  const dispatch = useDispatch();
  const filteredChips = useSelector((state) => state.product.productFilteredChips);
  const RoleId = parseInt(localStorageStore.getRole());

  let page = 1;
  const dataToBeFiltered = Object.fromEntries(filteredChips);
  dataToBeFiltered.account_no = account_no;
  
  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    const response = await dispatch(postProductFilterApi({ filteredData: dataToBeFiltered, page, size: currentSize + 10 }));
    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(prevSize => prevSize + 10); // Update currentSize after successful API call
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, dataToBeFiltered]);
  

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips.filter(([existingKey]) => existingKey !== key);

    // Dispatch the updated chips to the Redux store
    dispatch(setProductFilteredChips(updatedChips));

    // Call the clearFilterValues function to clear the corresponding state values
    setClearFilterValues(key);

    // Call the product filter API with the updated filteredChips
    dispatch(postProductFilterApi({ filteredData: Object.fromEntries(updatedChips), page, size : currentSize}));
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("RetailerProductComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("RetailerProductComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  return (
    <>
      <div className="card mt-2">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0">Details</h5>
          <div className="filter-card" style={{ display: "block" }}>
            {filteredChips.map(([key, value]) => (
             key !== "account_no" && <div className="chip" key={key}>
                <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                <span>{value}</span>
                <button type="button" className="zap">
                  <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                </button>
              </div>
            ))}
          </div>
        </div>
        <InfiniteScroll dataLength={productDetails.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="RetailerProductComponent">
          <div id ="RetailerProductComponent" className="card-datatable table-responsive pb-0" style={{ height: "360px", overflowY: "auto" }}>
            <table className=" table" id="orders">
              <thead>
                <tr className="align-baseline">
                  <th>Number</th>
                  <th width="10%">Name</th>
                  <th>Price <small>(USD)</small></th>
                  <th>Carrier</th>
                  <th>Country</th>
                  <th width="13%">Last Update</th>
                  <th> Recommended Price <small>(USD)</small></th>
                  <th>Final Price <small>(USD)</small></th>   
                  <th>Status</th>
                  <th width="10%">Action</th>
                </tr>
              </thead>
              <tbody>
                {productDetails &&
                  productDetails?.filter((productDetail) => productDetail.is_active === 1)?.map((productDetail, index) => (
                    <tr key={index}>
                      <td><span className="spantext" onClick={() => { setShowProductDetails(true); setProductAllDetails(productDetail);}}>{productDetail?.product_no}</span>
                      </td>
                      <td>{productDetail?.product_name}</td>
                      <td>{productDetail?.product_price}</td>
                      <td>{productDetail?.carrier_name}</td>
                      <td>{productDetail?.country_name}</td>   
                      <td>{moment(productDetail?.updatedAt).format("DD MMM YY")}</td>
                      <td>
                        <div className="d-flex">
                          <p className="mb-0 fw-medium">{(Number(productDetail?.product_price) + (Number(productDetail?.product_price) * Number(productDetail?.recommended_commission)) / 100).toFixed(2)}</p>
                          <p className="ms-2 text-warning mb-0">{productDetail?.recommended_commission}%</p>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex">
                          <p className="mb-0 fw-medium">{(Number(productDetail?.product_price) + (Number(productDetail?.product_price) * Number(productDetail?.final_commission)) / 100).toFixed(2)}</p>
                          <p className="ms-2 text-info mb-0">{productDetail?.final_commission}%</p>
                        </div>
                      </td>

                      <td>
                        <span className={productDetail?.is_active === 1 ? "badge bg-label-success" : "badge bg-label-danger"}>{productDetail?.is_active === 1 ? "Active" : "Inactive"}</span>
                      </td>

                      <td>
                        <span  className="me-3 spantext" title="Edit Price" onClick={() => { setShowModalBox(true); setProductAllDetails(productDetail); }}>
                          <i className="ti ti-cash " />
                        </span>
                        <span  className="spantext" title="Log" onClick={() => { setShowHistoryModalBox(true); setProductId(productDetail?.product_id); }}>
                          <i className="ti ti-clock " />
                        </span>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          </InfiniteScroll>
      </div>
      {showModalBox && <EditPriceModal showModalBox={showModalBox} setShowModalBox={setShowModalBox} productAllDetails={productAllDetails} userDetail = {userDetail} currentSize={currentSize} />}
      {showHistoryModalBox && <ProductHistoryModal showHistoryModalBox={showHistoryModalBox} setShowHistoryModalBox={setShowHistoryModalBox} productId={productId} account_no={account_no} RoleId={RoleId} />}
      {showProductDetails && <ViewProductModal showProductDetails={showProductDetails} setShowProductDetails={setShowProductDetails} productAllDetails={productAllDetails} />}
    </>
  );
};

export default ProductsGrid;
