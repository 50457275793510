import React, { useEffect, useState } from "react";
import CustomersGrid from "./CustomersGrid";
import StatusCard from "../../Shared/StatusCard/SmallStatusCard";
import CustomersFilterModal from "./CustomersFilterModal";
import { getMasterData } from "../../../services/userService";
import { useSelector } from "react-redux";

const ManageCustomers = () => {
  
  const [masterData, setMasterData] = useState("");
  const [clearFilterValues, setClearFilterValues] = useState("");
  const [currentSize, setCurrentSize] = useState(10);
  let id = "";

  useEffect(() => {
    fetchMasterData();

    // eslint-disable-next-line
  }, []);

  const fetchMasterData = async () => {
    try {
      const response = await getMasterData(id);
      setMasterData(response?.data);
    } catch (error) {
      console.error(error?.message);
    }
  };
  const headerDetails = useSelector((state) => state.adminCustomer.count);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h4 className="pt-1 mb-1">Customers</h4>
        <div>
          <CustomersFilterModal masterData={masterData} clearFilterValues={clearFilterValues} currentSize={currentSize} setClearFilterValues={setClearFilterValues}/>
        </div>
      </div>
      {/* Order List Table */}
      <div className="row">
        <div className="col-lg-3 col-6 mb-4">
          <StatusCard color="primary" icon="ti ti-user-circle ti-sm" value={headerDetails?.total_customers ?? 0} label="Total Customers" />
        </div>
        <div className="col-lg-3 col-6 mb-4">
          <StatusCard color="rounded-pill bg-label-success" icon="ti ti-user-check ti-sm" value={headerDetails?.total_active_customer ?? 0} label="Total Active" />
        </div>
        <div className="col-lg-3 col-6 mb-4">
          <StatusCard color="rounded-pill bg-label-danger" icon="ti ti-user-x ti-sm" value={headerDetails?.total_inactive_customer ?? 0} label="Total Inactive" />
        </div>
      </div>
      <CustomersGrid setClearFilterValues={setClearFilterValues} currentSize={currentSize} setCurrentSize={setCurrentSize} />
    </>
  );
};

export default ManageCustomers;
