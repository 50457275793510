import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setFilteredChips, postLogTransactionFilterApi, clearFilteredChips, setInitLogDetails } from "../../../redux/features/logTransactionSlice";
import { errorToast } from "../../Toasts/Toasts";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { chipName, formatCreditBalance } from "../../../utils/sharedFunctions";
import { Link } from "react-router-dom";
import ViewTransactionLogModal from "./ViewTransactionLogModal";
import ViewRetailerDistributorModal from "./ViewRetailerDistributorModal";
import { paymentMethod, topUpChannel } from "../../../utils/constants";
import { getRetailerDistributorInformation } from "../../../services/Admin";

const LogTransactionGrid = ({ setClearFilterValues, currentSize, setCurrentSize }) => {
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [showLogDetails, setShowLogDetails] = useState(false);
  const [logAllDetails, setShowlogAllDetails] = useState("");
  const [loadingForScrollbar, setLoadingForScrollbar] = useState(false);
  const [showRetailerDistributorDetails, setShowRetailerDistributorDetails] = useState(false);
  const [retailerDetails, setRetailerDetails] = useState(null);
  const [distributorDetails, setDistributorDetails] = useState(null);


  let page = 1;

  const dispatch = useDispatch();
  const filteredChips = useSelector((state) => state?.logTransaction?.filteredChips);

  const dataToBeFiltered = Object.fromEntries(filteredChips);
  // eslint-disable-next-line
  let transformedDataToBeFiltered = {};

  for (let key in dataToBeFiltered) {
    if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
    } else {
      transformedDataToBeFiltered[key] = dataToBeFiltered[key];
    }
  }

  useEffect(() => {
    dispatch(postLogTransactionFilterApi({ filteredData: {}, page, size: currentSize }));
    // eslint-disable-next-line
    return () => {
      dispatch(clearFilteredChips());
      dispatch(setInitLogDetails());
    };
  }, []);

  const logTransactionDetails = useSelector((state) => state.logTransaction.logDetails);

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips?.filter(([existingKey]) => existingKey !== key);

    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredChips(updatedChips));

    // Call the clearFilterValues function to clear the corresponding state values
    setClearFilterValues(key);

    const convertedChips = Object.fromEntries(updatedChips);

    let transformedChips = {};

    for (let key in convertedChips) {
      if (typeof convertedChips[key] === "object" && "label" in convertedChips[key] && "value" in convertedChips[key]) {
        transformedChips[key] = convertedChips[key].value;
      } else {
        transformedChips[key] = convertedChips[key];
      }
    }

    // Call the product filter API with the updated filteredChips
    dispatch(postLogTransactionFilterApi({ filteredData: transformedChips, page, size: currentSize + 10 }));
  };

  const loadMoreData = useCallback(async () => {
    if (loadingForScrollbar || !hasMoreData) return;
    setLoadingForScrollbar(true);
    const response = await dispatch(postLogTransactionFilterApi({ filteredData: transformedDataToBeFiltered, page, size: currentSize + 10 }));
    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoadingForScrollbar(false);
  }, [loadingForScrollbar, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, transformedDataToBeFiltered]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("logComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("logComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

    const getRetailerDistributorDetails = async (retailerId) => {
        try {
            const response = await getRetailerDistributorInformation(retailerId);
            if (response) {
                setRetailerDetails(response?.retailer);
                setDistributorDetails(response?.distributor);
                setShowRetailerDistributorDetails(true);
            }

        } catch (error) {
          errorToast(error?.response?.data?.message || "Something went wrong");
        }

    }

  return (
    <>
      <div className="card mt-2">
        <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
          <h5 className="card-title mb-0 me-5">Transactions Log</h5>
          <div className="filter-card" style={{ display: "block" }}>
            {filteredChips &&
              filteredChips?.map(([key, value]) => (
                <div className="chip" key={key}>
                  <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                  <span>
                    {chipName(key)} {":"} {key === "status_type_id" || key === "carrier_id" ? value?.label : value}
                  </span>
                  <button type="button" className="zap">
                    <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                  </button>
                </div>
              ))}
          </div>
        </div>
          <InfiniteScroll dataLength={logTransactionDetails?.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="logComponent">
            <div id="logComponent" className="card-datatable table-responsive pb-0" style={{ height: "360px", overflowY: "auto" }}>
              <table className=" table" id="orders">
                <thead>
                  <tr className="align-baseline">
                    <th>ORDER ID</th>
                    <th>TOTAL AMOUNT (USD)</th>
                    <th>PAID TO VENDOR (AFG)</th>
                    <th>PROCESSING FEE (USD)</th>
                    <th>TOP-UP CHANNEL</th>
                    <th>PAYMENT METHOD</th>
                    <th>TRANSACTION DATE</th>
                    <th>RECIPIENT NO.</th>
                    <th>PROVIDER</th>
                    <th>STATUS</th>
                  </tr>
                </thead>
                <tbody>
                  {logTransactionDetails?.length !== 0 ? (
                    logTransactionDetails?.map((logTransactionDetail, index) => (
                      <tr key={index}>
                        <td>
                          <Link
                            to=""
                            onClick={() => {
                              setShowLogDetails(true);
                              setShowlogAllDetails(logTransactionDetail);
                            }}
                          >
                            {" "}
                            {logTransactionDetail?.order_id}{" "}
                          </Link>{" "}
                        </td>

                        <td>{formatCreditBalance(logTransactionDetail?.total_amount_in_converted_currency)}</td>
                        <td>{parseInt(logTransactionDetail?.amount_paid_to_sitaragan)}</td>
                        <td>{(((parseFloat(logTransactionDetail?.vat) || 0) + (parseFloat(logTransactionDetail?.service_fee) || 0)).toFixed(2))}</td>
                        <td>{ logTransactionDetail?.payment_method_type === paymentMethod.Retailer ? topUpChannel.B2B : topUpChannel.B2C}</td>
                            <td>{logTransactionDetail?.payment_method_type === paymentMethod.Retailer ? <Link to="" onClick={() => {getRetailerDistributorDetails(logTransactionDetail?.retailer_id) }}on>Retailer</Link> : logTransactionDetail?.payment_method_type === paymentMethod.Stripe  ? "Stripe" : "Paypal"}</td>
                        <td>{moment(logTransactionDetail?.credited_at).format("DD MMM YY")}</td>
                        <td>
                          {logTransactionDetail?.phone_code} {logTransactionDetail?.mobile_number}
                        </td>
                        <td>{logTransactionDetail?.carrier}</td>
                        <td> <span className={logTransactionDetail?.status === "Successful" ? "badge px-2 bg-label-success" : logTransactionDetail?.status === "In Process" ? "badge px-2 bg-label-warning" : "badge px-2 bg-label-danger"}>{logTransactionDetail?.status}</span></td>
                      </tr>
                    ))
                  ) : (
                    <tr className="pt-3 ps-3">
                      <td colSpan="8">No Transaction Log found</td>
                    </tr>
                  )}
                  {loadingForScrollbar && ( <div className="tr"> {" "} <div className="td text">Loading...</div>{" "} </div> )}
                </tbody>
              </table>
            </div>
          </InfiniteScroll>
      </div>
      {showLogDetails && <ViewTransactionLogModal showLogDetails={showLogDetails} setShowLogDetails={setShowLogDetails} logAllDetails={logAllDetails} />}
          {showRetailerDistributorDetails && (
            <ViewRetailerDistributorModal
              showDetails={showRetailerDistributorDetails}
              setShowDetails={setShowRetailerDistributorDetails}
              retailerDetails={retailerDetails}
              distributorDetails={distributorDetails}
            />
          )}

    </>
  );
};

export default LogTransactionGrid;
