import moment from "moment";
import React from "react";
import { Modal } from "react-bootstrap";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";

const ViewProductDetails = ({ showProductDetails, setShowProductDetails, productAllDetails }) => {
 
  const handleClose = () => setShowProductDetails(false);
  const RoleId = parseInt(localStorageStore.getRole());

  return (
    <>
      <Modal show={showProductDetails} size="md" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" onClick={handleClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">View Product Details</h3>
              </div>
              <div className="row justify-content-center mt-2 mb-3">
                <div className="col-md-12 bg-lighter-blue rounded px-4">
                  <div className="d-flex w-100 flex-wrap justify-content-between py-1 mt-3 pb-3">
                    <div className="me-2">
                      <h6 className="mb-0">Description</h6>
                      <small className="text-muted">{productAllDetails?.product_description}</small>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Number</h6>
                  <small className="text-muted">{productAllDetails?.product_no}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Name</h6>
                  <small className="text-muted">{productAllDetails?.product_name}</small>
                </div>
                {RoleId !== userRoleConstants.RETAILER_ID && (
                  <div className="col-lg-6 mb-3">
                    <h6 className="mb-0">
                      Price <small>(USD)</small>
                    </h6>
                    <small className="text-muted">{productAllDetails?.product_price}</small>
                  </div>
                )}
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Carrier</h6>
                  <small className="text-muted">{productAllDetails?.carrier_name}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Country</h6>
                  <small className="text-muted">{productAllDetails?.country_name}</small>
                </div>

                {(RoleId !== userRoleConstants.ADMIN_ID) && <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Last Update</h6>
                  <small className="text-muted">{moment(productAllDetails?.updatedAt).format("DD MMM YY")}</small>
                </div>}
                {RoleId === userRoleConstants.RETAILER_ID && (
                  <>
                    <div className="col-lg-6 mb-3">
                      <h6 className="mb-0">
                        Recommended Price <small>(USD)</small>
                      </h6>
                      {RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID ? (
                        <small className="text-muted">
                          {(Number(productAllDetails?.product_price) + (Number(productAllDetails?.product_price) * Number(productAllDetails?.commission)) / 100).toFixed(2)}
                          <span>
                            <small className="text-warning ms-1">{productAllDetails?.commission}%</small>
                          </span>
                        </small>
                      ) : (
                        <small className="text-muted">
                          {(Number(productAllDetails?.product_price) + (Number(productAllDetails?.product_price) * Number(productAllDetails?.recommended_commission)) / 100).toFixed(2)}
                          <span>
                            <small className="text-warning  ms-1">{productAllDetails?.recommended_commission}%</small>
                          </span>
                        </small>
                      )}
                    </div>
                    <div className="col-lg-6 mb-3">
                      <h6 className="mb-0">
                        Final Price <small>(USD)</small>
                      </h6>
                      {RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID ? (
                        <small className="text-muted">
                          {(Number(productAllDetails?.product_price) + (Number(productAllDetails?.product_price) * Number(productAllDetails?.commission)) / 100).toFixed(2)}
                          <span>
                            <small className="text-info ms-1">{productAllDetails?.commission}%</small>
                          </span>
                        </small>
                      ) : (
                        <small className="text-muted">
                          {(Number(productAllDetails?.product_price) + (Number(productAllDetails?.product_price) * Number(productAllDetails?.final_commission)) / 100).toFixed(2)}
                          <span>
                            <small className="text-info ms-1">{productAllDetails?.final_commission}%</small>
                          </span>
                        </small>
                      )}
                    </div>
                  </>
                )}
                {RoleId === userRoleConstants.ADMIN_ID && (
                  <div className="col-lg-6 mb-3">
                    <h6 className="mb-0">
                      Commission <small>(%)</small>
                    </h6>
                    <small className="text-muted">{productAllDetails?.commission}%</small>
                  </div>
                )}
                {RoleId !== userRoleConstants.RETAILER_ID && (
                  <div className="col-lg-6 mb-3">
                    <h6 className="mb-0">Status</h6>
                    <small className="text-Successful">{productAllDetails?.is_active === 1 ? "Active" : "Inactive"}</small>
                  </div>
                )}
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewProductDetails;
