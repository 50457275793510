
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Select from "react-select";
import { useDispatch } from "react-redux";
import { setProductFilteredChips, clearProductFilteredChips, postProductFilterApi } from "../../../redux/features/productSlice";
import { errorToast, successToast } from "../../Toasts/Toasts";

const FilterModal = ({ masterData, clearFilterValues, account_no, currentSize}) => {

  // const [status, setStatus] = useState("");
  const [country, setCountry] = useState("");
  const [carrier, setCarrier] = useState("");
  const [productLastUpdate, setProductLastUpdate] = useState("");
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [productPrice, setProductPrice] = useState("");
  const [showModalBox, setShowModalBox] = useState(false);
  
  let page = 1;
  const dispatch = useDispatch();
  
  useEffect(() => {
    const filterValue = () => {
      if (clearFilterValues === "product_no") setNumber("");
      if (clearFilterValues === "name") setName("");
      if (clearFilterValues === "base_price") setProductPrice("");
      if (clearFilterValues === "carrier_id") setCarrier("");
      if (clearFilterValues === "country_id") setCountry("");
      if (clearFilterValues === "updatedAt") setProductLastUpdate("");
      // if (clearFilterValues === "is_active") setStatus("");
    };

    filterValue();

    // eslint-disable-next-line
  }, [clearFilterValues]);

  function handleClose() {
    setShowModalBox(false);
  }

  // const statusOption = [
  //   { label: "Active", value: "1" },
  //   { label: "Inactive", value: "0" },
  // ];

  const countryOption = () => {
    return masterData?.countries?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const carrierOption = () => {
    return masterData?.carriers?.map((data) => ({
      label: data?.name,
      value: data?.id,
    }));
  };

  const handleEventReceiveDate = (event, picker) => {
    picker.element.val(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
    setProductLastUpdate(picker.startDate.format("YYYY-MM-DD") + " - " + picker.endDate.format("YYYY-MM-DD"));
  };

  const handleCancelReceiveDate = (event, picker) => {
    picker.element.val("");
    setProductLastUpdate("");
  };

  const filterData = {
    product_no: number !== "" ? number : "",
    name: name !== "" ? name : "",
    country_id: country !== "" ? country?.value : "",
    carrier_id: carrier !== "" ? carrier?.value : "",
    // is_active: status !== "" ? status?.value : "",
    updatedAt: productLastUpdate !== "" ? productLastUpdate : "",
    base_price: productPrice !== "" ? productPrice : "",
    account_no: account_no,
  };

  const filterEmptyFields = (data) => {
    const filteredData = {};

    for (const key in data) {
      const value = data[key];

      if (value !== "" && value !== null) {
        filteredData[key] = value;
      }
    }

    return filteredData;
  };

  const filteredData = filterEmptyFields(filterData);

  const submitFilterData = async () => {
    const isFilterEmpty = Object.entries(filteredData).every(([key, value]) => {
      if (key === 'account_no') {
        return true;
      }
      return value === '';
    });
    
    if(!isFilterEmpty) {
      const response = await dispatch(postProductFilterApi({ filteredData: filteredData, page, size : currentSize}));

      dispatch(setProductFilteredChips(Object.entries(filteredData)));

      if(response?.meta?.requestStatus === "fulfilled"){
        successToast("Filter applied successfully")
      } else {
        errorToast("Error occurred while filtering")
      }

      handleClose();
    } else {  
      errorToast("No field selected for filter");
    }
  }

  const handleResetButton = () => {
    // setStatus("");
    setCountry("");
    setProductLastUpdate("");
    setName("");
    setNumber("");
    setProductPrice("");
    setCarrier("");
    dispatch(postProductFilterApi({ filteredData: {account_no: account_no}, page, size : currentSize}));
    dispatch(clearProductFilteredChips());
    handleClose();
  };
 

  return (
    <>
      <button type="button" className="btn btn-sm btn-primary rounded-pill btn-smwaves-effect waves-light me-2" onClick={(e) => setShowModalBox(true)}>
        <i className="ti ti-filter me-1" /> Filter
      </button>
      {
        <Modal show={showModalBox} size="md" centered>
          <div className="modal-simple">
            <div className="modal-content p-3 p-md-3">
              <Modal.Body>
                <button type="button" className="btn-close" onClick={handleClose} />
                <div className="text-left mb-0">
                  <h3 className="mb-3">Filter</h3>
                </div>
                <div className="row">
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Number</label>
                    <input type="string" placeholder="" className="form-control" value={number} onChange={(e) => setNumber(e.target.value)} min={0} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Name</label>
                    <input type="text" placeholder="" className="form-control" value={name} onChange={(e) => setName(e.target.value)} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Price</label>
                    <input type="number" placeholder="" className="form-control" value={productPrice} onChange={(e) => setProductPrice(e.target.value)} min={0} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Carrier</label>
                    <Select options={carrierOption()} isMulti={false} onChange={setCarrier} value={carrier} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Country</label>
                    <Select options={countryOption()} isMulti={false} onChange={setCountry} value={country} />
                  </div>
                  <div className="col-lg-6 mb-3">
                    <label className="form-label">Last Update</label>
                    <DateRangePicker
                      initialSettings={{
                        autoUpdateInput: false,
                        locale: { cancelLabel: "Clear", format: "YYYY-MM-DD" },
                        showDropdowns: true,
                        drops: "up",
                      }}
                      onApply={handleEventReceiveDate}
                      onCancel={handleCancelReceiveDate}
                    >
                      <input type="text" id="dateRangePickerReceiveDate" className="form-control" placeholder="Select Date" defaultValue={productLastUpdate} autoComplete="off" />
                    </DateRangePicker>
                  </div>
                  {/* <div className="col-lg-6 mb-3">
                    <label className="form-label">Status</label>
                    <Select options={statusOption} isMulti={false} onChange={setStatus} value={status} />
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-12 text-center mt-4">
                    <button type="submit" onClick={submitFilterData} className="btn btn-primary rounded-pill me-sm-3 me-1 waves-effect waves-light show-filter">
                      Submit
                    </button>
                    <button type="reset" className="btn btn-label-secondary rounded-pill btn-reset waves-effect" onClick={handleResetButton}>
                      Cancel
                    </button>
                  </div>
                </div>
              </Modal.Body>
            </div>
          </div>
        </Modal>
      }
    </>
  );
};

export default FilterModal
