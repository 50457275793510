import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { userRoleConstants } from "../../../utils/constants";
import localStorageStore from "../../../utils/localStorageStore";
import { downloadReceipt } from "../../../services/customerService";
import { errorToast } from "../../Toasts/Toasts";


const OrderDetailsModal = ({ showModalBox, setShowModalBox, topUpTransaction }) => {
  const RoleId = parseInt(localStorageStore.getRole());

  const handleCloseModal = () => {
    setShowModalBox(!showModalBox)
  }

  const handleReceipt = async (transactionId) => {
    try {
      const response = await downloadReceipt(transactionId);
      if(response) {
        const blob = new Blob([response], { type: "application/pdf" });
        const url = URL.createObjectURL(blob);
        let alink = document.createElement("a");
        alink.href = url;
        alink.download = "";
        alink.click();
      };

    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  }

  return (
    <Modal show={showModalBox}>
      <div className="modal-simple">
        <div className="modal-content p-3 p-md-3">
          <div className="modal-body">
            <button type="button" className="btn-close" onClick={handleCloseModal} />
            <div className="text-left mb-2 d-flex justify-content-between">
              {RoleId === userRoleConstants.RETAILER_ID ? <h3 className="mb-2">View order details</h3> : <h3 className="mb-2"> View transaction details</h3>}
              <div><button className="btn btn-sm btn-outline-primary rounded-pill" onClick={()=>{handleReceipt(topUpTransaction?.transaction_id)}}><i className="ti ti-download"></i> Receipt</button></div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Transaction ID</h6>
                <small className="text-muted">{topUpTransaction?.transaction_id}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Country Code</h6>
                <small className="text-muted">{topUpTransaction?.phone_code}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Mobile Number</h6>
                <small className="text-muted">{topUpTransaction?.mobile_number}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Requester's Name</h6>
                <small className="text-muted">{topUpTransaction?.requested_by}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Carrier</h6>
                <small className="text-muted">{topUpTransaction?.carrier}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">
                  Top-Up <small>(USD)</small>
                </h6>
                <small className="text-muted">{topUpTransaction?.total_amount_in_converted_currency}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Date</h6>
                <small className="text-muted"> {moment.utc(topUpTransaction?.credited_at).format('DD MMM YY, hh:mm A')}</small>
              </div>
              <div className="col-lg-6 mb-3">
                <h6 className="mb-0">Status</h6>
                <small className="text-Successful">{topUpTransaction?.status}</small>
              </div>
            </div>
            <div className="row justify-content-center mt-2">
              <div className="col-md-12 bg-lighter-blue rounded px-4">
                <div className="d-flex w-100 flex-wrap justify-content-between py-1 mt-3">
                  <div className="me-2">
                    <h6 className="mb-0">Amount</h6>
                  </div>
                  <div>
                    <div className="user-progress d-flex justify-content-end gap-1">
                      <p className="mb-0 fw-medium h6">
                        {Number(topUpTransaction?.amount_in_converted_currency || 0).toFixed(2)} <small>{topUpTransaction?.converted_currency_type}</small>
                      </p>
                    </div>
                    <p className="mb-0 fw-medium h6 text-primary">
                      {Number(topUpTransaction?.amount_in_source_currency || 0).toFixed(2)} <small>{topUpTransaction?.source_currency_type}</small>
                    </p>
                  </div>
                </div>
                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-1">
                  <div className="me-2">
                    <h6 className="mb-0">Service Fee</h6>
                  </div>
                  <div className="user-progress d-flex align-items-center gap-1">
                    <p className="mb-0 fw-medium h6">
                    {(parseFloat(topUpTransaction?.service_fee) + (topUpTransaction.commission ? parseFloat(topUpTransaction?.commission) : 0)).toFixed(2)}<small> {topUpTransaction?.converted_currency_type}</small> </p>
                  </div>
                </div>
                <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-1">
                  <div className="me-2">
                    <h6 className="mb-0">Taxes</h6>
                  </div>
                  <div className="user-progress d-flex align-items-center gap-1">
                    <p className="mb-0 fw-medium h6"> {Number(topUpTransaction?.vat || 0).toFixed(2)} <small> {topUpTransaction?.converted_currency_type}</small> </p>
                  </div>
                </div>
                <div className="d-flex justify-content-between border-top mt-2 pt-2">
                  <h6 className="fw-bold">Total</h6>
                  <div>
                  <p className="mb-0 fw-medium h6 text-primary">{Number(topUpTransaction?.total_amount_in_converted_currency || 0).toFixed(2)} <small>{topUpTransaction?.converted_currency_type}</small></p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default OrderDetailsModal;
