import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch } from "react-redux";
import { editUser } from "../../../services/userService";
import { clearFilteredChips, postUserFilterApi, setInitUserDetail } from "../../../redux/features/userSlice";
import moment from "moment";
import { successToast, errorToast } from "../../Toasts/Toasts";
import { userRoleConstants } from "../../../utils/constants";
import { chipName, formatCreditBalance, formatAbsoluteValue } from "../../../utils/sharedFunctions";
import RetailerEditModal from "../ManageUser/RetailerEditModal";
import localStorageStore from "../../../utils/localStorageStore";

const DetailsTable = ({ filteredChips, handleChipRemove, userDetails, setCurrentSize, currentSize, masterData, setId, acc_no, DistributorId, tableLoading, userAllDetails, setUserAllDetails}) => {
  const dispatch = useDispatch();
  const [hasMoreData, setHasMoreData] = useState(true);
  const [loading, setLoading] = useState(false);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [showEditModal,setShowEditModal] = useState(false);

  let page = 1;
  const RoleId = parseInt(localStorageStore.getRole());

  const handleCheckboxChange = async (event, userDetail) => {
    try {
      let userStatus = {
        is_active: event.target.checked,
      };

      const response = await editUser(userDetail?.id, userStatus);

      if (response?.success) {
        successToast(response?.message);
        let filteredData = Object.fromEntries(filteredChips);

        let transformedChips = {};

        for (let key in filteredData) {
          if (typeof filteredData[key] === "object" && "label" in filteredData[key] && "value" in filteredData[key]) {
            transformedChips[key] = filteredData[key].value;
          } else {
            transformedChips[key] = filteredData[key];
          }
        }

        if (acc_no) {
          transformedChips.user_id = DistributorId;
        } else if (RoleId !== userRoleConstants.ADMIN_ID && RoleId !== userRoleConstants.MANAGER_ID && RoleId !== userRoleConstants.SUPERVISOR_ID) {
          transformedChips.role_id = userRoleConstants.RETAILER_ID;
        }

        dispatch(postUserFilterApi({ filteredData: transformedChips, page, size: currentSize }));
      } else {
        successToast(response?.message);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message || "An error occurred. Please try again later.");
    }
  };

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);
    let filterData = Object.fromEntries(filteredChips);

    let transformedChips = {};

    for (let key in filterData) {
      if (typeof filterData[key] === "object" && "label" in filterData[key] && "value" in filterData[key]) {
        transformedChips[key] = filterData[key].value;
      } else {
        transformedChips[key] = filterData[key];
      }
    }

    if (acc_no) {
      transformedChips.user_id = DistributorId;
    } else if (RoleId !== userRoleConstants.ADMIN_ID && RoleId !== userRoleConstants.MANAGER_ID && RoleId !== userRoleConstants.SUPERVISOR_ID) {
      transformedChips.role_id = userRoleConstants.RETAILER_ID;
    }

    const response = await dispatch(postUserFilterApi({ filteredData: transformedChips, page, size: currentSize + 10 }));

    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);

    // eslint-disable-next-line
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, filteredChips]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("retailerDetailsComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("retailerDetailsComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
    // eslint-disable-next-line
  }, [loadMoreData]);

  useEffect(() => {
    return () => {
      dispatch(clearFilteredChips());
      dispatch(setInitUserDetail());
    };

    // eslint-disable-next-line
  },[]);

  const saveUserIdToLocalStorage = (id) => {
    localStorage.setItem("distributorOrRetailerId", id);
  };

  const getSuffix = (day) => {
    const dayNumber = parseInt(day, 10);
    const lastTwoDigits = dayNumber % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
      return "th";
    }

    switch (dayNumber % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <div className="card">
      <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
        <h5 className="card-title mb-0">Details</h5>
        <div className="filter-card" style={{ display: "block" }}>
          {filteredChips?.map(
            ([key, value]) =>
              key !== "role_id" &&
              key !== "user_id" && (
                <div className="chip" key={key}>
                  <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                  <span>
                    {chipName(key)} {":"} {key === "country_id" || key === "is_active" || key === "credit_day" || key === "billing_type_id" ? value?.label : value}
                  </span>
                  <button type="button" className="zap">
                    <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                  </button>
                </div>
              )
          )}
        </div>
      </div>
      <InfiniteScroll dataLength={userDetails?.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="retailerDetailsComponent">
        <div id="retailerDetailsComponent" className="card-datatable table-responsive" style={{ height: "400px", overflowY: "auto" }}>
          <table className=" table" id="orders">
            <thead>
              <tr className="align-baseline">
                <th>A/C No.</th>
                {acc_no ? <th width="15%">Reailer Name</th> : (RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) ? <th width="15%">Distributor Name</th> : <th width="15%">Retailer Name</th>}
                {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && !acc_no && <th width="10%">City</th>}
                {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && !acc_no && <th width="10%">Country</th>}
                {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID)  && <th width="15%">Location</th>}
                {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && acc_no && <th width="15%">Location</th>}
                <th>Onboarding Date</th>
                <th>Credit Limit</th>
                {!acc_no && (
                  <>
                    <th>Credit Day</th>
                    <th>Billing Type</th>
                  </>
                )}
                <th>Live Balance </th>
                {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID)&& <th width="10%">No. of Top-Ups</th>}
                {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && acc_no && <th width="15%">No. of Top-Ups</th>}
                <th>
                  {" "}
                  Commission <small>(%)</small>{" "}
                </th>
                {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && !acc_no && (
                  <>
                    <th>Users</th>
                    <th>No. of Retailers</th>
                  </>
                )}
                {(RoleId !== userRoleConstants.MANAGER_ID && RoleId !== userRoleConstants.SUPERVISOR_ID && RoleId !== userRoleConstants.SALES_REPRESENTATIVE_ID) && <th>Status</th>}
                {(RoleId !== userRoleConstants.MANAGER_ID && RoleId !== userRoleConstants.SUPERVISOR_ID && RoleId !== userRoleConstants.SALES_REPRESENTATIVE_ID && !acc_no ) && <th>Action</th>}
              </tr>
            </thead>
            <tbody>
              {tableLoading ? (<tr><td className="text">Loading.....</td></tr>) :
                (userDetails?.length !== 0 ?
                  (userDetails?.map ((userDetail, index) => (
                  <tr key={index}>
                    {!acc_no ? (
                      <td>
                        <Link to={RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID ? `/retailer/${userDetail?.account_no}/profile` : `/distributor/${userDetail?.account_no}/profile`} onClick={() => saveUserIdToLocalStorage(userDetail?.id)}>
                          {userDetail?.account_no}
                        </Link>
                      </td>
                    ) : (
                      <td>{userDetail?.account_no}</td>
                    )}
                    <td>{userDetail?.name}</td>
                    {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && !acc_no && (
                      <td>
                        {userDetail?.city}
                      </td>
                    )}
                    {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && !acc_no && (
                      <td>
                      {userDetail?.country_name}
                      </td>
                    )}

                    {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID) && (
                      <td>
                        {userDetail?.state_name}, {userDetail?.country_code}{" "}
                      </td>
                    )}
                    {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && acc_no && (
                      <td>
                        {userDetail?.state_name}, {userDetail?.country_code}{" "}
                      </td>
                    )}
                    <td>{moment(userDetail?.createdAt).format("DD MMM YY")}</td>
                    <td>{userDetail && userDetail.credit_limit !== 0 ? formatAbsoluteValue(userDetail.credit_limit) : "N/A"}</td>
                    {!acc_no && (
                      <>
                        <td> {parseInt(userDetail?.credit_day) !== 0 ? ( <> {userDetail?.credit_day}<sup>{getSuffix(userDetail?.credit_day)}</sup> </> ) : ( "N/A" )} </td>
                        <td className={userDetail?.billing_type_name === "Credit" ? "text-primary" : "text-info"}>{userDetail?.billing_type_name}</td>
                      </>
                    )}
                    <td> {(formatAbsoluteValue(userDetail?.credit_balance))} <small>USD</small>{" "} </td>
                    {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID) && <td className="text-warning">{formatCreditBalance(userDetail?.total_topups)}</td>}
                    {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && acc_no && <td className="text-warning">{formatCreditBalance(userDetail?.total_topups)}</td>}
                    <td>{userDetail?.commission ? userDetail.commission.toFixed(2) : '0.00'}</td>

                    {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID) && !acc_no && (
                      <>
                        <td>{userDetail?.total_distributor_users}</td>
                        <td>{userDetail?.total_retailers}</td>
                      </>
                    )}
                    {(RoleId !== userRoleConstants.MANAGER_ID && RoleId !== userRoleConstants.SUPERVISOR_ID && RoleId !== userRoleConstants.SALES_REPRESENTATIVE_ID) && <td>
                      <div className="d-flex">
                        <input className="input-switch" type="checkbox" id={`demo-${index}`} checked={userDetail?.is_active === 1} onChange={(event) => handleCheckboxChange(event, userDetail)} />
                        <label className="label-switch" htmlFor={`demo-${index}`} />
                        <span className="info-text ms-2" />
                      </div>
                    </td>}
                    {(RoleId !== userRoleConstants.MANAGER_ID && RoleId !== userRoleConstants.SUPERVISOR_ID && RoleId !== userRoleConstants.SALES_REPRESENTATIVE_ID && !acc_no) && (
                      <td>
                        {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (
                          <Link to={`/retailer/${userDetail?.account_no}/products`} style={{ color: "#0282C3", cursor: "pointer" }} title="Products">
                            <i className="ti ti-cash me-2"></i>
                          </Link>
                        )}
                        <Link
                          to=""
                          onClick={() => {
                            setUserAllDetails(userDetail);
                            setShowEditModal(true);
                          }}
                          style={{ color: "#0282C3", cursor: "pointer" }}
                          title="edit"
                        >
                          <i className="ti ti-edit"></i>
                        </Link>
                    </td>)}
                  </tr>))) : (<tr className="pt-3 ps-3"><td colSpan="12">No record found</td></tr>)
                )
              }
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      {showEditModal && <RetailerEditModal userAllDetails={userAllDetails} masterData={masterData} setShowEditModal={setShowEditModal} showEditModal={showEditModal} currentSize={currentSize} setId={setId} filteredChips={filteredChips} />}
    </div>
  );
};

export default DetailsTable;
