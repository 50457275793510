import React from "react";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { formatCreditBalance } from "../../../utils/sharedFunctions";

const ViewTransactionLogModal = ({ showLogDetails, setShowLogDetails, logAllDetails }) => {
  const handleClose = () => setShowLogDetails(false);
  return (
    <>
      <Modal show={showLogDetails} size="md" centered>
        <div className="modal-simple">
          <div className="modal-content p-3 p-md-3">
            <Modal.Body>
              <button type="button" className="btn-close" onClick={handleClose} />
              <div className="text-left mb-4">
                <h3 className="mb-2">Transaction details</h3>
              </div>

              <div className="row">
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Transaction ID</h6>
                  <small className="text-muted">{logAllDetails?.transaction_id}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Total Amount ({logAllDetails?.converted_currency_type})</h6>
                  <small className="text-muted">{formatCreditBalance(logAllDetails?.total_amount_in_converted_currency)}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Transaction Date</h6>
                  <small className="text-muted">{moment.utc(logAllDetails?.createdAt).format("DD MMM YY, hh:mm A")}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Total Amount ({logAllDetails?.source_currency_type})</h6>
                  <small className="text-muted"><td>{Number(logAllDetails?.total_amount_in_source_currency || 0).toFixed(2)}</td></small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">Status</h6>
                  <small className="text-Successful">{logAllDetails?.status}</small>
                </div>
                <div className="col-lg-6 mb-3">
                  <h6 className="mb-0">API Response</h6>
                  <small className="text-muted">{logAllDetails?.response_message}</small>
                </div>
              </div>
            </Modal.Body>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ViewTransactionLogModal;
