import React from "react";
import { Link } from "react-router-dom";
import localStorageStore from "../../utils/localStorageStore";
import { userRoleConstants } from "../../utils/constants";

const Sidebar = () => {
  const RoleId = parseInt(localStorageStore.getRole());

  return (
    <aside id="layout-menu" className="layout-menu menu-vertical menu bg-menu-theme">
      <div className="app-brand demo">
        <Link to="#" className="app-brand-link">
          <img src="../../assets/img/small-logo.png" alt="Shaad" width="30px" className="large-menu" />
          <img src="../../assets/img/logo.png" alt="Shaad" width="100px" className="small-menu" />
        </Link>
        <span className="layout-menu-toggle menu-link text-large ms-auto">
          <i className="ti menu-toggle-icon d-none d-xl-block ti-sm align-middle" />
          <i className="ti ti-x d-block d-xl-none ti-sm align-middle" />
        </span>
      </div>
      <div className="menu-inner-shadow" />
      <ul className="menu-inner py-1">
        {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.PROCUREMENT_ID) && (
          <li className="menu-item">
            <Link to="/dashboard" className="menu-link">
              <i className="menu-icon tf-icons ti ti-dashboard" />
              <div data-i18n="Manage Top-Up">Dashboard</div>
            </Link>
          </li>
        )}
        {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.SUPERVISOR_ID)&& (
          <li className="menu-item">
            <Link to="/distributors" className="menu-link">
              <i className="menu-icon tf-icons ti ti-users" />
              <div data-i18n="log">Distributors</div>
            </Link>
          </li>
        )}
        {/* {RoleId === userRoleConstants.ADMIN_ID && (
          <li className="menu-item">
            <Link to="#" className="menu-link">
              <i className="menu-icon tf-icons ti ti-user-circle" />
              <div data-i18n="log">Customers</div>
            </Link>
          </li>
        )} */}
        {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID) && (
          <li className="menu-item">
            <Link to="/retailers" className="menu-link">
              <i className="menu-icon tf-icons ti ti-building-store"></i>
              <div data-i18n="Manage Top-Up">Retailers</div>
            </Link>
          </li>
        )}
        {RoleId === userRoleConstants.RETAILER_ID && (
          <li className="menu-item">
            <Link to="/b2c-topup" className="menu-link">
              <i className="menu-icon tf-icons ti ti-square-arrow-up" />
              <div data-i18n="log">Top-Up</div>
            </Link>
          </li>
        )}
        {RoleId === userRoleConstants.RETAILER_ID && (
          <li className="menu-item">
            <Link to="/order" className="menu-link">
              <i className="menu-icon tf-icons ti ti-list" />
              <div data-i18n="log">Orders</div>
            </Link>
          </li>
        )}
        {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID || RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID || RoleId === userRoleConstants.ACCOUNTANT_ID) && (
          <li className="menu-item">
            <Link to="/billing" className="menu-link">
              <i className="menu-icon tf-icons ti ti-receipt"></i>
              <div data-i18n="Manage Billing">Billing</div>
            </Link>
          </li>
        )}
        {/* {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID || RoleId === userRoleConstants.ADMIN_ID) && (
          <li className="menu-item">
            <Link to="#" className="menu-link">
              <i className="menu-icon tf-icons ti ti-receipt" />
              <div data-i18n="Manage credit">Billing</div>
            </Link>
          </li>
        )} */}
        {RoleId === userRoleConstants.DISTRIBUTOR_ID && (
          <li className="menu-item">
            <Link to="/distributor/users" className="menu-link">
              <i className="menu-icon tf-icons ti ti-users" />
              <div data-i18n="Manage Orders">Users</div>
            </Link>
          </li>
        )}
        {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.SALES_REPRESENTATIVE_ID) && (
          <li className="menu-item">
            <Link to="/customers" className="menu-link">
            <i className="menu-icon tf-icons ti ti-user-circle"></i>
              <div data-i18n="log">Customers</div>
            </Link>
          </li>
        )}
        {/* {(RoleId === userRoleConstants.ADMIN_ID) && (
          <li className="menu-item">
            <Link to="/carriers" className="menu-link">
            <i className="menu-icon tf-icons ti ti-access-point"></i>
              <div data-i18n="log">Carriers</div>
            </Link>
          </li>
        )} */}
        {(RoleId === userRoleConstants.DISTRIBUTOR_ID || RoleId === userRoleConstants.RETAILER_ID || RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.DISTRIBUTOR_USER_ID) && (
          <li className="menu-item">
            <Link to="/products" className="menu-link">
              <i className="menu-icon tf-icons ti ti-box" />
              <div data-i18n="log">Products</div>
            </Link>
          </li>
        )}
        {RoleId === userRoleConstants.ADMIN_ID && (
          <li className="menu-item">
            <Link to="/configurations" className="menu-link">
              <i className="menu-icon tf-icons ti ti-settings" />
              <div data-i18n="log">Configurations</div>
            </Link>
          </li>
        )}
        {(RoleId === userRoleConstants.ADMIN_ID || RoleId === userRoleConstants.MANAGER_ID || RoleId === userRoleConstants.ACCOUNTANT_ID)&& (
          <li className="menu-item">
            <Link to="/log" className="menu-link">
              <i className="menu-icon tf-icons ti ti-history" />
              <div data-i18n="log">Transactions Log</div>
            </Link>
          </li>
        )}
      </ul>
    </aside>
  );
};

export default Sidebar;
