import React, { useEffect, useRef, useState } from "react";
import LoginTopUpBackground from "../Layout/AuthLayout/LoginTopUpBackground";
import { Link, useLocation } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { Controller, useForm } from "react-hook-form";
import { convertCurrency } from "../../services/customerService";
import { errorToast } from "../Toasts/Toasts";
import SelectPaymentMethod from "../Shared/SelectPaymentMethod";

const CustomAmountTopUp = () => {
  const [convertAmount, setConvertAmount] = useState('');
  const [loader, setLoader] = useState(true);
  const [showBox, setShowBox] = useState(false);
  const [selectedValue, setSelectedValue] = useState();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const keywordRef = useRef("");
  const [amount, setAmount] = useState(location?.state?.Amount || "");
  const [btnDisabled, setBtnDisabled] = useState(false);

  let carrierId = location?.state?.carrierId || 1;

  const getSelectedContactFromLocalStorage = () => {
    // Retrieve JSON string from localStorage
    const selectedContactDataJSON = localStorage.getItem("selectedContactData");

    // Convert JSON string to object
    const selectedContactData = JSON.parse(selectedContactDataJSON);

    return selectedContactData;
  };
  const selectedContact = getSelectedContactFromLocalStorage();

  const { control } = useForm({ defaultValues: { retailer_phone_number: `${selectedContact?.phoneCode}${selectedContact?.phoneNumber}` ?? "" } });

  const handleChange = (value) => {
    console.log(value);
  };

  useEffect(() => {
    fetchRecordConvertCurrency();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const fetchRecordConvertCurrency = async () => {
    try {
      const response = await convertCurrency({ amount: location?.state?.Amount });
      if (response?.success) {
        setConvertAmount(response?.data);
        setLoader(false);
      } else {
        errorToast(response?.message);
        setLoader(true);
      }
    } catch (error) {
      errorToast(error?.response?.data?.message);
      setLoader(true);
    }
  };

  const contactId = localStorage.getItem("ContactId");
  const userId = localStorage.getItem("userId");

  const handlePaymentGateway = async () => {
    try {
      const obj = {
        converted_currency: convertAmount?.convertedCurrency,
        mobile_number: selectedContact?.phoneNumber,
        original_currency_type: convertAmount?.sourceCurrency,
        phone_code: selectedContact?.phoneCode,
        service_fee: convertAmount?.serviceFee,
        converted_amount: convertAmount?.convertedAmount,
        source_amount: convertAmount?.sourceAmount,
        total_converted_amount: convertAmount?.totalConvertedAmount,
        total_source_amount: convertAmount?.totalSourceAmount,
        vat: convertAmount?.vat,
        customer_contact_id: contactId,
        requested_by: userId,
      };
      const rechargeDetails = JSON.stringify(obj);
      localStorage.setItem("rechargeDetails", rechargeDetails);
      setShowBox(true);
    } catch (error) {
      errorToast(error?.response?.data?.message);
    }
  };

  let timeoutId;
  const handleTopUpAmountChange = async (event) => {
    setBtnDisabled(true);
    const keyword = event?.target?.value?.trim();
    setAmount(keyword); // Update state when input changes
    setLoading(true);
  
    if (keyword !== "" && keyword.length >= 2) {
      keywordRef.current = keyword; // Update the ref with the latest keyword
      clearTimeout(timeoutId);
      timeoutId = setTimeout(async () => {
        if (keywordRef.current === keyword) {
          try {
            const response = await convertCurrency({ amount: parseFloat(keyword) });
            setConvertAmount(response?.data);
            setLoading(false);
            setBtnDisabled(false);
          } catch (error) {
            setConvertAmount(null);
            setLoading(false);
            errorToast(error?.response?.data?.message || error?.response?.data?.errors[0]?.message || "Something went wrong");
            setBtnDisabled(true);
          }
        }
      }, 600);
    } else {
      setConvertAmount(null);
      setLoading(false);
      setBtnDisabled(true);
    }
  };
 

  
  return (
    <section id="hero-animation">
      <div id="landingHero" className="section-py landing-hero position-relative">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center">
              <div className="top-up">
                <div className="authentication-wrapper authentication-basic container-p-y pt-0 mt-3">
                  <div className="authentication-inner row col-sm-12 col-md-4 col-12 topup_box py-2 pt-0">
                    <div className="card card-topup bg-outline-primary mt-0">
                      <div className="card-header d-flex justify-content-center pb-2 text-center">
                        <div className="back-button-products">
                          <Link to="/customer/profile" type="button">
                            <i className="ti ti-arrow-narrow-left ti-sm back-button" />
                          </Link>
                        </div>
                        <div className="card-title mb-3">
                          <h4 className="m-0 me-2">Your Top-Up Info</h4>
                        </div>
                      </div>
                      <div className="row pb-3 border-bottom border-bottom-dashed">
                        <div className="row ps-5">
                          <div className="col-md-6 d-flex mb-3 justify-content-between">
                            <div className="d-flex">
                              <Controller
                                name="retailer_phone_number"
                                control={control}
                                rules={{
                                  required: "Required",
                                }}
                                render={({ field }) => (
                                  <>
                                    <PhoneInput
                                      className="form-control-phone-input"
                                      international
                                      countryCallingCodeEditable={false}
                                      // countrySelectProps={{ unicodeFlags: true }}
                                      value={field.value}
                                      onChange={(value) => {
                                        handleChange(value);
                                      }}
                                    />
                                  </>
                                )}
                              />
                              <h6 className="mb-0 me-1" style={{ alignContent: "center" }}>
                                {selectedContact?.phoneCode + " " + selectedContact?.phoneNumber}
                              </h6>
                            </div>
                          </div>
                          <div className="col-md-6 d-flex">
                            {/* {selectedContact?.carrierName === "Roshan" ? <img src="../../assets/img/roshan-lg.png" alt="Roshan" className="me-3" width="60" height="30" /> : <img src="../../assets/img/etisalat-lg.png" alt="Etisalat" className="me-3" width="70" height="30" />} */}
                            <span className="me-1">{selectedContact?.carrierName} </span> <small> (carrier)</small>
                          </div>
                        </div>
                      </div>
                      {loader ? (
                        <div className="text-center mt-3 mb-3">
                          <span className="spinner-border h-15px w-15px align-middle text-danger" />
                        </div>
                      ) : (
                        <div className="row justify-content-center px-4">
                          <div className="col-xl-8 col-md-7 col-7 mb-0 mt-2">
                            <div className="mt-0 mb-4">
                              <div>
                                <label htmlFor="" className="form-label text-center d-flex justify-content-center" style={{ fontSize: "15px" }}>
                                  Amount
                                </label>
                                <div className="input-group input-group-merge rounded-pill  text-center">
                                  <input
                                    type="number"
                                    className="form-control"
                                    onChange={(e) => {
                                      handleTopUpAmountChange(e);
                                    }}
                                    placeholder={500}
                                    min={0}
                                    max={5000}
                                    value={amount}
                                  />
                                  <span className="input-group-text">AFN</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          {convertAmount && (
                            <div className="col-md-12">
                              <div className="d-flex w-100 flex-wrap justify-content-between py-2 mt-3">
                                <div className="me-2">
                                  <h5 className="mb-0">Amount</h5>
                                </div>
                                <div>
                                  <div className="user-progress d-flex align-items-center gap-1">
                                    <p className="mb-0 fw-medium h5">
                                      <span className="text-primary me-2">
                                        ({convertAmount?.sourceAmount} <small>{convertAmount?.sourceCurrency}</small>)
                                      </span>
                                      <span className="h5">
                                        {convertAmount?.convertedAmount} <small>{convertAmount?.convertedCurrency}</small>
                                      </span>
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                                <div className="me-2">
                                  <h5 className="mb-0">Service Fee</h5>
                                </div>
                                <div className="user-progress d-flex align-items-center gap-1">
                                  <p className="mb-0 fw-medium h5">
                                    {convertAmount?.serviceFee} <small>{convertAmount?.convertedCurrency}</small>
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex w-100 flex-wrap align-items-center justify-content-between py-2">
                                <div className="me-2">
                                  <h5 className="mb-0">Taxes</h5>
                                </div>
                                <div className="user-progress d-flex align-items-center gap-1">
                                  <p className="mb-0 fw-medium h5">
                                    {convertAmount?.vat} <small>{convertAmount?.convertedCurrency}</small>
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex justify-content-between border-top mt-2 pt-2">
                                <h4>Total</h4>
                                <div>
                                  <p className=" h3">
                                    <span className="text-primary me-2 fs-5">
                                      ({convertAmount?.totalSourceAmount} <small>{convertAmount?.sourceCurrency}</small>)
                                    </span>
                                    {convertAmount?.totalConvertedAmount} <small>{convertAmount?.convertedCurrency}</small>
                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                          {loading && (
                            <div className="text-center">
                              <span className="mt-2 spinner-border h-15px w-15px align-middle text-danger" />
                            </div>
                          )}
                          <div className="col-md-12">
                            {/* <Link to="/customer/profile" className="btn btn-md btn-primary rounded-pill mt-2 mb-4" onClick={handlePaymentGateway}>
                            Checkout
                          </Link> */}
                            <button className="btn btn-md btn-primary rounded-pill mt-2 mb-4" onClick={handlePaymentGateway} disabled={btnDisabled}>
                              Checkout
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <LoginTopUpBackground />
      </div>
      {showBox && <SelectPaymentMethod showBox={showBox} setShowBox={setShowBox} setSelectedValue={setSelectedValue} selectedValue={selectedValue} amount={Math.round(convertAmount?.totalConvertedAmount * 100) / 100} carrierId={carrierId} />}
    </section>
  );
};

export default CustomAmountTopUp;
