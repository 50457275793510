import React, { useState, useEffect, useCallback } from "react";
import OrderDetailsModal from "./OrderDetailsModal";
import { clearFilteredChips, getTopUpDetailApi, setFilteredChips, setInitTransactionDetail } from "../../../redux/features/topUpSlice";
import { useDispatch, useSelector } from "react-redux";
import { errorToast } from "../../Toasts/Toasts";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import { chipName, formatCreditBalanceUptoFourDecimal } from "../../../utils/sharedFunctions";
import { userRoleConstants } from "../../../utils/constants";

const OrdersTable = ({ topUpTransaction, acc_no, setCurrentSize, currentSize, setClearFilterValues, roleId, DISTRIBUTOR_ID }) => {
  const [showModalBox, setShowModalBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const [hasMoreData, setHasMoreData] = useState(true);
  const [currentResultSetLength, setCurrentResultSetLength] = useState(0);
  const [indexForModalDisplay, setIndex] = useState(0);
  let page = 1;
  const dispatch = useDispatch();
  const filteredChips = useSelector((state) => state?.topUpSlice?.filteredChips);
  const {DISTRIBUTOR_USER_ID, SALES_REPRESENTATIVE_ID } = userRoleConstants

  const handleModalDisplay = (index) => {
    setIndex(index);
    setShowModalBox(!showModalBox);
  };

  const switchStatusBadgeClasses = (status) => {
    switch (status) {
      case "Successful":
        return "bg-label-success";
      case "In Process":
        return "bg-label-warning";
      case "Failed":
        return "bg-label-danger";
      default:
        return "bg-label-warning";
    }
  };

  const handleChipRemove = (key) => {
    // Remove the chip from the filteredChips state
    const updatedChips = filteredChips.filter(([existingKey]) => existingKey !== key);

    // Dispatch the updated chips to the Redux store
    dispatch(setFilteredChips(updatedChips));

    // Call the clearFilterValues function to clear the corresponding state values
    setClearFilterValues(key);

    const convertedChips = Object.fromEntries(updatedChips);

    let transformedChips = {};

    for (let key in convertedChips) {
      if (typeof convertedChips[key] === "object" && "label" in convertedChips[key] && "value" in convertedChips[key]) {
        transformedChips[key] = convertedChips[key].value;
      } else {
        transformedChips[key] = convertedChips[key];
      }
    }

    // Call the product filter API with the updated filteredChips
    dispatch(getTopUpDetailApi({ filteredData: transformedChips, page, size: currentSize + 10 }));
  };

  const dataWithoutAccountNumber = Object.fromEntries(filteredChips);

  const dataToBeFiltered = Object.fromEntries(filteredChips);

  dataToBeFiltered.account_no = acc_no;

  const loadMoreData = useCallback(async () => {
    if (loading || !hasMoreData) return;
    setLoading(true);

    let transformedDataWithoutAccountNumber = {};

    for (let key in dataWithoutAccountNumber) {
      if (typeof dataWithoutAccountNumber[key] === "object" && "label" in dataWithoutAccountNumber[key] && "value" in dataWithoutAccountNumber[key]) {
        transformedDataWithoutAccountNumber[key] = dataWithoutAccountNumber[key].value;
      } else {
        transformedDataWithoutAccountNumber[key] = dataWithoutAccountNumber[key];
      }
    }
    // eslint-disable-next-line
    let transformedDataToBeFiltered = {};
  
    for (let key in dataToBeFiltered) {
      if (typeof dataToBeFiltered[key] === "object" && "label" in dataToBeFiltered[key] && "value" in dataToBeFiltered[key]) {
        transformedDataToBeFiltered[key] = dataToBeFiltered[key].value;
      } else {
        transformedDataToBeFiltered[key] = dataToBeFiltered[key];
      }
    }

    const response = await dispatch(getTopUpDetailApi((roleId === DISTRIBUTOR_ID || roleId === DISTRIBUTOR_USER_ID || roleId === SALES_REPRESENTATIVE_ID) ? { filteredData: transformedDataToBeFiltered, page, size: currentSize + 10 } : { filteredData: transformedDataWithoutAccountNumber, page, size: currentSize + 10 }));
    if (response?.meta?.requestStatus === "fulfilled") {
      const newData = response.payload.records;
      if (newData?.length > 0 && newData?.length > currentResultSetLength) {
        setCurrentSize(currentSize + 10);
        setCurrentResultSetLength(newData?.length);
      } else {
        setHasMoreData(false);
      }
    } else {
      errorToast("Error occurred while loading more data");
    }
    setLoading(false);
  }, [loading, hasMoreData, currentSize, setCurrentSize, currentResultSetLength, dispatch, page, DISTRIBUTOR_ID, SALES_REPRESENTATIVE_ID, DISTRIBUTOR_USER_ID, roleId, dataToBeFiltered, dataWithoutAccountNumber]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollContainer = document.getElementById("ordersComponent");
      if (scrollContainer.scrollHeight - scrollContainer.scrollTop === scrollContainer.clientHeight) {
        // User has scrolled to the bottom
        loadMoreData();
      }
    };

    const scrollContainer = document.getElementById("ordersComponent");
    if (scrollContainer) {
      scrollContainer.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainer) {
        scrollContainer.removeEventListener("scroll", handleScroll);
      }
    };
  }, [loadMoreData]);

  useEffect(() => {
  
    return () => {
      dispatch(clearFilteredChips());
      dispatch(setInitTransactionDetail());
    };
  }, [])
  

  return (
    <div className="card">
      <div className="card-header pb-3 pt-3 border-bottom d-flex justify-content-between">
        <h5 className="card-title mb-0">Orders</h5>
        <div className="filter-card" style={{ display: "block" }}>
          {filteredChips?.map(
            ([key, value]) =>
              key !== "account_no" && (
                <div className="chip" key={key}>
                  <input type="checkbox" name="filters[]" defaultValue="a" defaultChecked="" />
                  <span>{chipName(key)} {":"} {key === "carrier_id" || key === "status_type_id" ? value?.label : value}</span>
                  <button type="button" className="zap">
                    <i className="ti ti-x fs-6" onClick={() => handleChipRemove(key)} />
                  </button>
                </div>
              )
          )}
        </div>
      </div>
      <InfiniteScroll dataLength={topUpTransaction?.length} next={loadMoreData} hasMore={hasMoreData} loader={loading ? <h4>Loading...</h4> : null} scrollableTarget="ordersComponent">
        <div id="ordersComponent" className="card-datatable table-responsive pb-0"  style={{ height: "400px", overflowY: "auto" }}>
          <table className=" table" id="orders">
            <thead>
              <tr className="align-baseline">
                <th>Order ID</th>
                <th>Country Code</th>
                <th>Mobile Number</th>
                <th>Requester's Name</th>
                <th width="12%">TOP-UP</th>
                <th>Carrier</th>
                <th>
                  {" "}
                  COMMISSION <small>(%)</small>{" "}
                </th>
                <th>Date</th>
                <th>status</th>
              </tr>
            </thead>
            <tbody>
              {topUpTransaction?.length !== 0 ? 
                (topUpTransaction?.map((transactionDetail, index) => (
                  <tr key={index}>
                    <td> <span style={{ color: "#0282C3", cursor: "pointer" }} onClick={() => { handleModalDisplay(index); }} > {transactionDetail?.order_id} </span> </td>
                    <td>{transactionDetail?.phone_code}</td>
                    <td>{transactionDetail?.mobile_number}</td>
                    <td>{transactionDetail?.requested_by}</td>
                    <td> <p className="mb-0"> {formatCreditBalanceUptoFourDecimal(transactionDetail?.total_amount_in_converted_currency ?? 0)} <small> USD</small> </p> </td>
                    <td>{transactionDetail?.carrier}</td>
                    <td>{formatCreditBalanceUptoFourDecimal(transactionDetail?.commission ?? 0)} </td>
                    <td>{moment(transactionDetail?.credited_at).format("DD MMM YY")}</td>
                    <td> <span className={`badge px-2 ${switchStatusBadgeClasses(transactionDetail?.status)}`}>{transactionDetail?.status}</span> </td>
                  </tr>
                ))) : (<tr className="pt-3 ps-3"><td colSpan="9">No Record found</td></tr>)
              }
            </tbody>
          </table>
        </div>
      </InfiniteScroll>
      {showModalBox && <OrderDetailsModal showModalBox={showModalBox} setShowModalBox={setShowModalBox} topUpTransaction={topUpTransaction[indexForModalDisplay]} />}
    </div>
  );
};

export default OrdersTable;
